'use client';

import { useEffect } from 'react';
import * as Sentry from '@sentry/nextjs';
import Error from 'next/error';
type Props = {
  error: Error & {
    digest?: string;
  };
};
export default function GlobalError({
  error
}: Props) {
  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);
  return <html data-sentry-component="GlobalError" data-sentry-source-file="global-error.tsx">
      <body>
        {/*
          This is the default Next.js error component but it doesn't allow omitting the statusCode property yet.
          @see https://docs.sentry.io/platforms/javascript/guides/nextjs/manual-setup/#react-render-errors-in-app-router for more info.
         */}
        <Error statusCode={(undefined as never)} data-sentry-element="Error" data-sentry-source-file="global-error.tsx" />
      </body>
    </html>;
}